<script>
import * as icons from 'vue-feather-icons'

export default {
    functional: true,
    name: 'featherIcon',
    props: {
        icon: { required: true },
        svgClasses: { type: [String, Object, Array], default: "" },
        badge: {},
        size: {  
            type: String,
            default: '24',
            validator: function validator(s) {
                return !isNaN(s) || s.length >= 2 && !isNaN(s.slice(0, s.length - 1)) && s.slice(-1) === 'x';
            }
        }
    },
    render(h, { props, data }) {
        // Add feather classes
        data.staticClass ? data.staticClass = data.staticClass + ' feather-icon select-none relative' : data.staticClass = 'feather-icon select-none relative'
        let svg = h(icons[props.icon], { props: {size : props.size }, class: props.svgClasses })
        let children = [svg]
        let badgeEl = h('span', { class: 'feather-icon-badge bg-primary text-white h-5 w-5 absolute rounded-full text-xs flex items-center justify-center', style: 'top: -7px; right: -5px' }, [props.badge])
        if (props.badge) children.push(badgeEl)
        return h('span', data, children)
    }
}

</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/featherIcon.scss"
</style>
