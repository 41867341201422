<template>
    <button v-if="isElsiCard" type="button" class="btn" :class="btnStyle" @click="$emit('back-clicked')">
        <feather-icon :icon="icon" />
        <feather-icon v-if="chevronLeft && chevron" icon="ChevronLeftIcon" />
        {{ label }}
        <feather-icon v-if="!chevronLeft && chevron" icon="ChevronRightIcon" />
    </button>
    <button v-else-if="!isElsiCard" type="button" class="btn" :class="btnStyle" @click="clicked">
        <feather-icon :icon="icon" />
        <feather-icon v-if="chevronLeft && chevron" icon="ChevronLeftIcon" />
        {{ label }}
        <feather-icon v-if="!chevronLeft && chevron" icon="ChevronRightIcon" />
    </button>
</template>

<script>
import FeatherIcon from './FeatherIcon.vue'

export default {
    name: "button",
    components: {
        FeatherIcon
    },
    props: {
        label: String,
        chevron: Boolean,
        chevronLeft: Boolean,
        link: Boolean,
        linkTo: String,
        isElsiCard: Boolean,
        isDownload: Boolean,
        btnStyle: {
            type: String,
            default: "btn-primary"
        },
        icon: String
    },
    methods: {
        clicked() {
            if (this.label === 'Zurück') {
                this.$router.back()
            }
            else {
                if (this.linkTo === '/') {
                    this.$store.commit('survey/DELETE_PROJECT')
                }
                this.$router.push(this.linkTo)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-link,
.btn-primary {
    background: $elsi-color-blue;
    color: white;

    &:hover {
        background: darken($elsi-color-blue, 8);
    }
}

.btn-secondary {
    background: $elsi-color-dunkelgrau;
    color: $elsi-color-schrift-normal;

    &:hover {
        background: darken($elsi-color-dunkelgrau, 8);
    }
}

button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 12px;

    height: 47px;
    border-radius: 3px;

    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: #FFFFFF;
    text-decoration: none;
    border: 0 none;

    .b-icon.bi,
    .btn .b-icon.bi {
        font-size: inherit;
    }

}

.full-width {
    width: 100%;
}
</style>
